import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useAppContext } from "../libs/contextLib";
import { countCompaniesInIndustries, convertToGeoJson } from "../libs/Util";

// eslint-disable-next-line no-unused-vars
const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => (
  <input type={type} name={name} checked={checked} onChange={onChange} />
);

export default function MapFilters({ companies }) {
  const { setCheckedItems, checkedItems, map } = useAppContext();

  // eslint-disable-next-line no-undef,no-unused-vars
  useEffect(() => {
  }, [checkedItems]);

  function handleChange(event) {

    // eslint-disable-next-line no-unused-vars
    const val = event.target.checked;
    const name = event.target.name;


    // we need to work with a new array as setting the state with setCheckedItems(checkedItems) does not work
    const newArray = [...checkedItems];

    if (newArray.includes(name)) {
      // if we uncheck the item then delete this from the array
      let index = newArray.indexOf(name);
      newArray.splice(index, 1);
    } else {
      newArray.push(name);
    }

    const companiesGeoJson = convertToGeoJson(companies.nodes);


    let filtered = companiesGeoJson.filter((company) => {
      let companyIndustriesArr = company.properties.industry.split(',');
      let found = false;

      // as each company can have multiple industries seperated with a comma we need to loop over the industries
      for (let i = 0; i < companyIndustriesArr.length; i++) {
        if (newArray.includes(companyIndustriesArr[i].trim())) {
          found = true;
        }
      }

      return found;

      // return newArray.includes(company.properties.industry);
    });


    map.getSource("companies").setData({
      type: "FeatureCollection",
      features: newArray.length > 0 ? filtered : companiesGeoJson,
    });

    setCheckedItems(newArray);

    // setCheckedItems({ ...checkedItems, [name]: val });

    // if(checkedItems[event.target.name]){
    //   checkedItems[event.target.name] = !checkedItems[event.target.checked];
    //   console.log(checkedItems[event.target.name]);
    // } else {
    //   checkedItems[event.target.name] = true;
    // }
    // setCheckedItems(...checkedItems, {checkedItems[event.target.name] });
    // console.log(checkedItems);
  }

  const { companiesWithIndustries } = useStaticQuery(graphql`
      query {
          companiesWithIndustries: allStrapiCompanies(limit: 100) {
              nodes {
                  industry
              }
          }
      }
  `);

  // count how many comapnies are in each industry
  const IndustriesWithCount = countCompaniesInIndustries(companiesWithIndustries.nodes);

  return (
    <nav className="text-xs mx-2 mb-40 flex flex-wrap filter-group leading-none">
      <h3>Filter by industry:</h3>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col">
          {IndustriesWithCount.map((industry) => (
            <label
              key={industry.name}
              className="inline-flex items-center mt-3"
            >
              <input
                type="checkbox"
                name={industry.name}
                className="form-checkbox h-5 w-5 text-red-600"
                checked={checkedItems.includes(industry.name)}
                onChange={handleChange}
              />{" "}
              <span className="ml-2 text-gray-300">{industry.name}</span>
              <span className="ml-2 text-gray-300">{industry.count}</span>
            </label>
          ))}
        </div>
      </div>
    </nav>
  );
}
