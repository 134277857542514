export const convertToGeoJson = (companies) => {
  return companies.map((company) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [company.longitude, company.latitude],
    },
    properties: {
      id: company.id,
      name: company.name,
      description: company.description,
      address: company.address,
      logo: company.logo ? company.logo : "",
      website: company.website,
      careersPage: company.careersPage,
      size: company.size,
      type: "Company",
      Xing: company.Xing,
      Linkedin: company.Linkedin,
      youtube: company.youtube,
      industry: company.industry,
      headquarters: company.headquarters,
    },
  }));
};

// this returns a object of all the industries with the cound of how much companies each industry has
// the object is also sorted from biggest to smallest
export function countCompaniesInIndustries(companies) {

  const industries = companies
    .map((company) => company.industry.split(','))
    .reduce((acc, industry) => {

      // because we did the split (that turned the string "ind1 ind2 ind3" to an array ["ind1","ind2","ind3"])
      // we need another map to loop over them also

      industry.map((industry) => {
        let industryClean = industry.trim();
      if (industryClean) {
        // check if this is an existing industry if it is increment it by one otherwise create a new entry in the accumulator and set it to 1
        if (acc[industryClean]) {
          acc[industryClean].count += 1;
        } else {
          acc[industryClean] = {
            count: 1,
            name: industryClean,
          };
        }
      }
      });



      return acc;
    }, {});
  return Object.values(industries).sort((a, b) => b.count - a.count);
}
