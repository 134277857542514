import React from "react";
import Img from "gatsby-image";
import { useAppContext } from "../libs/contextLib";
import { colorsMapping } from "../libs/colorsMapping";
import { Link } from 'gatsby';
import { FaGlobeEurope, FaUsers, FaLinkedinIn, FaYoutubeSquare, FaTwitterSquare, FaXingSquare, FaInstagramSquare } from 'react-icons/fa';

function Sidebar({ selectedCompany }) {
  const { setSelectedCompany } = useAppContext();
  // add check if there is logo before parsing
  let logo = null;
  if (selectedCompany.logo) {
    logo = JSON.parse(selectedCompany.logo);
  }


  return (
    <section className="fixed flex-1 w-full md:w-1/4 lg:w-1/4 h-full bg-gray-200 z-9999 shadow-2xl overflow-y-scroll border antialiased">
      <div
        className="cursor-pointer"
        onClick={() => {
          setSelectedCompany(false);
        }}
      >
        <svg
          className="h-6 w-6 ml-auto mt-2 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>

      <div className="p-4">
        <h2 className="mb-0 text-center text-gray-800 font-semibold tracking-wider uppercase">
        <Link to={`company/${selectedCompany.slug}`}>{selectedCompany.name}</Link>
        </h2>

        <div className="flex items-center justify-around">
          <span className="text-center text-xs text-gray-600 mt-2 ">
            {selectedCompany.address}{" "}
          </span>
        </div>

        <div className="p-8 mt-2 ">
          {logo ? (
            <Img
              imgStyle={{ objectFit: "contain" }}
              className="object-contain rounded-lg m-auto h-16 w-16"
              fluid={logo.childImageSharp.fluid}
            />
          ) : (
            ""
          )}
        </div>

        <div className="flex items-center justify-around w-full mb-4 border-b-4 pb-4">
          {selectedCompany.twitter ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={selectedCompany.twitter}
            >
              <FaTwitterSquare className="h-8 w-4 fill-current " style={{ color: "#1DA1F2" }} />
            </a>
          ) : (
            ""
          )}
          {selectedCompany.xing ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={selectedCompany.xing}
            >
              <FaXingSquare className="h-8 w-4 fill-current" style={{ color: "#126567" }} />
            </a>
          ) : (
            ""
          )}
          {selectedCompany.linkedin ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={selectedCompany.linkedin}
            >
              <FaLinkedinIn className="h-8 w-4 fill-current" style={{ color: "#2867B2" }} />
            </a>
          ) : (
            ""
          )}
          {selectedCompany.instgram ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={selectedCompany.instgram}
            >
              <FaInstagramSquare className="h-8 w-4 fill-current" style={{ color: "#833AB4" }} />
            </a>
          ) : (
            ""
          )}
          {selectedCompany.youtube ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={selectedCompany.youtube}
            >
              <FaYoutubeSquare className="h-8 w-4 fill-current" style={{ color: "#FF0000" }} />
            </a>
          ) : (
            ""
          )}

        </div>


        <div className="flex flex-col items-start">
          {selectedCompany.industry ? (
            <div className="flex items-center justify-around">
              <span className="text-md text-gray-900 mt-2 font-semibold mx-2">
                Industry{" "}
              </span>
              {/* <span className={"text-md text-center text-gray-700 mt-2" colorsMapping[colorsMapping.indexOf(selectedCompany.industry) + 1]}> */}
              <span
                style={{
                  backgroundColor: colorsMapping[colorsMapping.indexOf(selectedCompany.industry) + 1] ? '#1982C4' : 'green',
                  borderRadius: "3px",
                  padding: "1px 4px",
                }}
                className={`text-xs text-center text-white mt-2 ${
                  colorsMapping[
                  colorsMapping.indexOf(selectedCompany.industry) + 1
                    ]
                }`}
              >
                {selectedCompany.industry}{" "}
              </span>
            </div>
          ) : (
            ""
          )}
          {selectedCompany.careersPage ? (
            <div className="flex items-center justify-around">
              <span className="text-md text-gray-900 mt-2 font-semibold mx-2">
                Careers Page{" "}
              </span>
              {/*<span className="text-md text-center text-gray-700 mt-2 ">{selectedCompany.careersPage} </span>*/}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={selectedCompany.careersPage}
              >
                <svg
                  className="h-4 w-4 mt-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </a>
            </div>
          ) : (
            ""
          )}
          {selectedCompany.headquarters ? (
            <div className="flex items-center justify-around">
              <span className="text-md text-gray-900 mt-2 font-semibold mx-2">
                Headquarters{" "}
              </span>
              <span className="text-xs text-gray-700 mt-2">
                {selectedCompany.headquarters}{" "}
              </span>
            </div>
          ) : (
            ""
          )}

          {selectedCompany.website ? (
            <div className="flex items-center justify-around mt-2">
              <span className="text-md text-gray-900 font-semibold mx-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={selectedCompany.website}
                >
                <FaGlobeEurope/>
              </a>
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={selectedCompany.website}
                className="text-sm no-underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              >
                {selectedCompany.website}
              </a>

            </div>
          ) : (
            ""
          )}
          {selectedCompany.size ? (
            <div className="flex items-center justify-around mt-2">
              <span className="text-md text-gray-900 font-semibold mx-2">
              <FaUsers className="w-6 h-6" />{" "}
              </span>
              <span className="text-sm font-medium text-gray-700 mx-2">
                {selectedCompany.size}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}

export default Sidebar;
