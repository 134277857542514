import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
// import SEO from "../components/seo";
import Map from "../components/map";
import SEO from "../components/seo";
import { AppContext } from "../libs/contextLib";
import Sidebar from "../components/sidebar";
import MapFilters from "../components/mapFilters";
import { slide as Menu } from "react-burger-menu";
// import filterIcon from "../images/filter.svg";
import { GoSettings } from 'react-icons/go';


export default function IndexPage({ data }) {
  const [selectedCompany, setSelectedCompany] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [map, setMap] = useState(null);

  return (
    <AppContext.Provider
      value={{ setSelectedCompany, setCheckedItems, checkedItems, map, setMap }}
    >
      <Layout>
        <SEO
          keywords={[
            `Tech companies in germany`,
            `Work in germany`,
            `information technology germany`,
          ]}
          title="Explore and find Tech companies in Germany"
        />
        <div>
          {selectedCompany ? (
            <Sidebar selectedCompany={selectedCompany} />
          ) : null}
        </div>
        <Menu
          customBurgerIcon={<GoSettings/>}
          noOverlay
          right
          className="overflow-y-auto "
        >
          <MapFilters companies={data.companies} />
        </Menu>

        <Map companies={data.companies} />
      </Layout>
    </AppContext.Provider>
  );
}

export const query = graphql`
  query companiesQuery {
    companies: allStrapiCompanies(limit: 1000) {
      nodes {
        name
        website
        id
        city
        address
        careersPage
        Linkedin
        Xing
        youtube
        twitter
        instgram
        size
        longitude
        headquarters
        latitude
        industry
        slug
        logo {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;