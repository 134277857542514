/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// import styled from "styled-components";
import { useAppContext } from "../libs/contextLib";
import { colorsMapping } from "../libs/colorsMapping";
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
// const OtherComponent = loadable(() => import('@mapbox/mapbox-gl-geocoder'))
// import loadable from '@loadable/component'


const sources = {
  companies: {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
    'cluster': true,
    'clusterMaxZoom': 9,
    'clusterRadius': 60
  },
};


const convertToGeoJson = (companies) => {
  return companies.map((company) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [company.longitude, company.latitude],
    },
    properties: {
      id: company.id,
      name: company.name,
      description: company.description ? company.description : "",
      address: company.address,
      logo: company.logo ? company.logo : "",
      website: company.website ? company.website: '',
      careersPage: company.careersPage ? company.careersPage: '',
      size: company.size ? company.size: '',
      xing: company.Xing ? company.Xing : '',
      linkedin: company.Linkedin ? company.Linkedin : '',
      youtube: company.youtube ? company.youtube : '',
      twitter: company.twitter ? company.twitter : '',
      instgram: company.instgram ? company.instgram : '',
      industry: company.industry ? company.industry : '',
      headquarters: company.headquarters ? company.headquarters : '',
      slug: company.slug ? company.slug : ''
    },
  }));
};





// eslint-disable-next-line react/prop-types
export default function Map({ companies }) {


  function forwardGeocoder(query) {
    let matchingFeatures = [];
    for (let i = 0; i < sources.companies.data.features.length; i++) {
      let feature = sources.companies.data.features[i];
// handle queries with different capitalization than the source data by calling toLowerCase()
      if (
        feature.properties.name
          .toLowerCase()
          .search(query.toLowerCase()) !== -1
      ) {
// add a tree emoji as a prefix for custom data results
// using carmen geojson format: https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
        feature['place_name'] = '🏢 ' + feature.properties.name.toUpperCase();
        feature['center'] = feature.geometry.coordinates;
        feature['place_type'] = ['park'];
        matchingFeatures.push(feature);
      }
    }
    return matchingFeatures;
  }

  const { setSelectedCompany, setMap } = useAppContext();

  sources.companies.data.features = convertToGeoJson(companies.nodes);

  // this ref holds the map DOM node so that we can pass it into Mapbox GL
  const mapNode = useRef(null);

  // this ref holds the map object once we have instantiated it, so that we
  // can use it in other hooks
  const mapRef = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API_TOKEN;

    const map = new mapboxgl.Map({
      container: mapNode.current,
      style: "mapbox://styles/mapbox/streets-v11?optimize=true", // stylesheet location
      center: [10.4515, 51.16715],
      zoom: 5,
    });

    mapRef.current = map;
    window.map = map; // for easier debugging and querying via console


    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        localGeocoder: forwardGeocoder,
        zoom: 13,
        placeholder: 'Enter Company OR City',
        mapboxgl: mapboxgl,
        marker: false,
        countries: 'de'
      }), 'top-left'
    );


    map.on("load", () => {
      setMap(map);
      console.log("-- map onload --");
      map.resize();

      map.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        (error, image) => {
          if (error) throw error;
          map.addImage("marker", image, { sdf: true });
        }
      );

      // add sources to the map - they are received as props
      Object.entries(sources).forEach(([id, source]) => {
        map.addSource(id, source);
      });


      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'companies',
        filter: ['has', 'point_count'],
        paint: {
// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
// with three steps to implement three types of circles:
//   * Blue, 20px circles when point count is less than 100
//   * Yellow, 30px circles when point count is between 100 and 750
//   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            20,
            '#f1f075',
            150,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'companies',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.addLayer({
        id: `companies-layer`,
        source: "companies",
        filter: ['!', ['has', 'point_count']],
        type: "symbol",
        layout: {
          // full list of icons here: https://labs.mapbox.com/maki-icons
          "icon-image": "marker", // this will put little croissants on our map
          "icon-allow-overlap": true,
          'text-allow-overlap': true,
          "icon-size": 0.8,
          "text-field": ["get", "name"],
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-size": 13,
          "text-offset": [0, 1.4],
          "text-anchor": "top",
        },
        // paint: {
        //   "icon-color": colorsMapping,
        // },
        paint: {
          "icon-color": colorsMapping,
        },
      });


      // or we can also get the layers as props and add all layers
      /*layers.forEach(layer => {
        map.addLayer(layer)
      })*/
    });

    // inspect a cluster on click
    map.on('click', 'clusters', function (e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      var clusterId = features[0].properties.cluster_id;
      map.getSource('companies').getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });


    map.on("click", "companies-layer", function (e) {
      const data = {
        companyCLicked: e.features[0].properties.name
      };

      // typeof window !== "undefined" && window.gtag("event", "click", { ...data });


      // var coordinates = e.features[0].geometry.coordinates.slice();
      // console.log(e.features[0].properties);
      // console.log(e.features);
      // setSelectedCompany(e.features[0].properties);
      // console.log(e.features[0]);
      setSelectedCompany(e.features[0].properties);
      // setSelectedCompany('new ');
    });

    // when this component is destroyed, remove the map
    return () => {
      map.remove();
    };
  }, []);

  return <div className="h-screen w-full mapbox" ref={mapNode} />;
}
